<template>
  <div class="distributionPaper">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <div>分配考试到班级，配置好班级后，班级学员在班级详情和考试中心可以参加考试</div>
      <table-list
        ref="studentsByOrgTable"
        class="tableList"
        :loading="loading"
        :search-form="searchForm"
        :data="list"
        :options="{
          selection: true,
          reserveSelection: true,
          rowKey: 'examId',
          selectable: selectEvent,
        }"
        :columns="columns(this)"
        :operates="operates(this)"
        :pager="pager"
        @search="onSearch"
        @selectionChange="handleSelectionChange"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></table-list>
      <div class="btn">
        <el-button type="primary" @click="dialogVisible = false">
          关闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 搜索框配置
const searchForm = [
  {
    label: '班级名称',
    prop: 'keyword',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'classId',
    label: 'ID',
  },
  {
    prop: 'className',
    label: '班级名称',
  },
  {
    prop: 'headTeacher',
    label: '班主任老师',
    render: (h, { row }) => {
      return h('div', [
        h(
          'div',
          row.headTeacher.map(v => v.studentName),
        ),
      ])
    },
  },
  {
    prop: 'courses',
    label: '班级课程',
    render: (h, { row }) => {
      return h('div', [
        h(
          'div',
          row.courses.map(v => v.courseName),
        ),
      ])
    },
  },
  {
    prop: 'createTime',
    label: '创建时间',
    width: '150px',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '配置考试',
    method: _this.handleConfigure,
    isShow: row => {
      return !row.flag
    },
  },
  {
    label: '取消配置',
    method: _this.handleCancel,
    isShow: row => {
      return row.flag
    },
  },
]
import TableList from '@/components/TableList'
import { testPaperList, distributionTest, cancelDistributionTest } from '@/api/examination'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      dialogTitle: '试题库列表',
      loading: false,
      dialogVisible: false,
      list: [],
      searchForm,
      columns,
      operates,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      examId: '',
      selectStudent: [],
    }
  },
  methods: {
    show(examId) {
      this.dialogVisible = true
      this.examId = examId
      this.pager.current = 1
      this.testPaperListData(examId)
      this.$nextTick(() => {
        this.$refs.studentsByOrgTable.$refs.tableList.clearSelection()
      })
    },
    async testPaperListData(examId) {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(testPaperList({ current, size, examId, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list
      this.pager.total = res.data.total
    },
    async handleConfigure(row) {
      const [, err] = await to(distributionTest({ classId: row.classId, examId: this.examId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '配置成功' })
      this.testPaperListData(this.examId)
      // this.dialogVisible = false
      this.$emit('success')
    },
    async handleCancel(row) {
      const [, err] = await to(
        cancelDistributionTest({ classId: row.classId, examId: this.examId }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '取消配置成功' })
      this.testPaperListData(this.examId)
      // this.dialogVisible = false
      this.$emit('success')
    },
    handleSelectionChange(val) {
      this.selectStudent = val
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.testPaperListData(this.examId)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.testPaperListData(this.examId)
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.testPaperListData(this.examId)
    },
    selectEvent(row) {
      return !row.flag
    },
  },
}
</script>

<style scoped lang="scss">
.distributionPaper {
  h ::v-deep.table-list {
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      display: none;
    }
  }
  .btn {
    text-align: right;
    margin-top: 50px;
  }
}
.tableList {
  height: 500px;
  overflow-y: scroll;
}
</style>
