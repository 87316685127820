<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:56:53
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-23 16:56:04
-->
<template>
  <div class="testList">
    <div class="btns">
      <el-button type="primary" class="childBtn" @click="addTest">
        <i class="el-icon-document-add"></i>
        创建考试
      </el-button>
      <el-dropdown class="childBtn" @command="examinationSetting">
        <el-button>
          <i class="el-icon-setting"></i>
          考试设置
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="testSetting">试卷设置</el-dropdown-item>
          <el-dropdown-item command="ruleSetting">考试规则设置</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-button class="childBtn" @click="shareTest">
        <i class="el-icon-share"></i>
        分享试卷
      </el-button> -->
      <!-- <el-dropdown class="childBtn" @command="resultAnalysis">
        <el-button>
          <i class="el-icon-s-data"></i>
          成绩分析
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>统计/分析</el-dropdown-item>
          <el-dropdown-item command="lookResult">查看成绩</el-dropdown-item>
          <el-dropdown-item>下载试卷</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      <el-button @click="lookResult">
        查看成绩
      </el-button>
    </div>

    <table-list
      class="tableList"
      :title="tableTitle"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="list"
      :options="{ singleCurrent: true, fixScrollTable: true }"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      @search="onSearch"
      @singleSelectChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>

    <shareCode ref="code"></shareCode>

    <distributionPaper
      ref="distributionPaper"
      @success="getExaminationListData()"
    ></distributionPaper>
  </div>
</template>
<script>
//表格标题
const tableTitle = '考试列表'
// 搜索框配置
const searchForm = _this => [
  {
    label: '考试名称',
    prop: 'keyword',
  },
  {
    label: '分配班级',
    prop: 'classId',
    type: 'select',
    children: _this.classList.map(item => ({ value: item.classId, label: item.className })),
  },
  {
    label: '考试类型',
    prop: 'type',
    type: 'select',
    children: [
      { value: 1, label: '练习-随机试卷' },
      { value: 2, label: '练习-自定义试卷' },
      { value: 3, label: '考试-随机试卷' },
      { value: 4, label: '考试-自定义试卷' },
    ],
  },
  {
    label: '创建时间',
    prop: 'time',
    type: 'picker',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'examId',
    label: 'ID',
  },
  {
    prop: 'examTitle',
    label: '考试名称',
    twoLines: true,
    // render(h, { row }) {
    //   return h(
    //     'div',
    //     {
    //       class: 'tableRowTitle',
    //     },
    //     row.examTitle,
    //   )
    // },
  },
  {
    prop: 'type',
    label: '考试类型',
    formatter: row => {
      if (row.type == 1) {
        return '练习-随机试卷'
      } else if (row.type == 2) {
        return '练习-自定义试卷'
      } else if (row.type == 3) {
        return '考试-随机试卷'
      } else {
        return '考试-自定义试卷'
      }
    },
  },
  {
    prop: 'examClassList',
    label: '分配班级',
    formatter: row => {
      let nameArray = row.examClassList.map(element => element.className)
      return nameArray.join('、')
    },
  },
  {
    prop: 'scoreTotal',
    label: '考试总分',
    align: 'right',
  },
  {
    prop: 'questionTotal',
    label: '题目数量',
    align: 'right',
  },
  {
    prop: 'completeTotal',
    label: '完成次数',
    width: '100px',
    align: 'right',
  },
  {
    prop: 'createUserName',
    label: '创建人',
    width: '100px',
  },
  {
    prop: 'createTime',
    label: '创建时间',
  },
]
// 表格操作按钮配置
const operates = _this => [
  // {
  //   label: '预览',
  //   method: _this.handleLook,
  // },
  {
    label: '发布',
    method: _this.handleRelease,
    isShow: row => row.publishStatus == 0 || row.publishStatus == 2,
  },
  {
    label: '停止',
    method: _this.handleStop,
    isShow: row => row.publishStatus == 1,
  },
  {
    label: '分配考试',
    method: _this.handleDistribution,
  },
  {
    label: '删除',
    method: _this.handleDelete,
  },
]
import {
  getExaminationList,
  releaseTestPaper,
  stopTestPaper,
  classSelectList,
  testDel,
} from '@/api/examination'
import TableList from '@/components/TableList'
import distributionPaper from './components/distributionPaper.vue'
import shareCode from './components/shareCode.vue'
import to from 'await-to'
export default {
  components: {
    TableList,
    shareCode,
    distributionPaper,
  },
  data() {
    return {
      tableTitle,
      searchForm,
      columns,
      operates,
      loading: false,
      list: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      selectList: {},
      classList: [],
    }
  },
  mounted() {
    this.getExaminationListData()
    this.classSelectListData()
  },
  methods: {
    async getExaminationListData() {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(getExaminationList({ current, size, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list
      this.pager.total = res.data.total
    },
    async classSelectListData() {
      const [res, err] = await to(classSelectList())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.classList = res.data
    },
    addTest() {
      this.$router.push('createdTest')
    },
    lookResult() {
      if (!this.selectList.examId) {
        return this.$message({ type: 'warning', message: '请选择查看的试卷' })
      }
      this.$router.push({ path: 'testResult', query: { examId: this.selectList.examId } })
    },
    shareTest() {
      if (!this.selectList.examId) {
        return this.$message({ type: 'warning', message: '请选择分享的试卷' })
      }
      this.$refs.code.show(this.selectList.examId, this.selectList.examTitle)
    },
    resultAnalysis(cmditem) {
      switch (cmditem) {
        case 'lookResult':
          this.lookResult()
          break
      }
    },
    examinationSetting(cmditem) {
      if (!this.selectList.examId) {
        return this.$message({ type: 'warning', message: '请选择操作的试卷' })
      }
      switch (cmditem) {
        case 'testSetting':
          this.routerTest(cmditem)
          break
        case 'ruleSetting':
          this.routerTest(cmditem)
          break
      }
    },
    routerTest(i) {
      if (this.selectList.type == 1 || this.selectList.type == 3) {
        if (i == 'testSetting') {
          this.$router.push({
            path: 'random',
            query: { editStstus: 'edit', navIndex: 0, examId: this.selectList.examId },
          })
        } else {
          this.$router.push({
            path: 'random',
            query: { editStstus: 'edit', navIndex: 1, examId: this.selectList.examId },
          })
        }
      } else {
        if (i == 'testSetting') {
          this.$router.push({
            path: 'custom',
            query: { editStstus: 'edit', navIndex: 0, examId: this.selectList.examId },
          })
        } else {
          this.$router.push({
            path: 'custom',
            query: { editStstus: 'edit', navIndex: 1, examId: this.selectList.examId },
          })
        }
      }
      i
    },
    handleSelectionChange(val) {
      this.selectList = val
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.time && queryParams.time.length !== 0) {
        queryParams.start = queryParams.time[0]
        queryParams.end = queryParams.time[1]
      } else {
        queryParams.start = ''
        queryParams.end = ''
      }
      this.queryParams = queryParams
      this.getExaminationListData()
    },
    // 预览
    handleLook(row) {
      console.log(row)
    },
    // 分配考试
    async handleDistribution(row) {
      this.$refs.distributionPaper.show(row.examId)
    },
    //发布
    async handleRelease(row) {
      const [, err] = await to(releaseTestPaper({ examId: row.examId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '发布成功' })
      this.getExaminationListData()
    },
    async handleStop(row) {
      const [, err] = await to(stopTestPaper({ examId: row.examId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '停止成功' })
      this.getExaminationListData()
    },
    // 删除
    async handleDelete(row) {
      this.$confirm('你确认要删除吗?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const [res, err] = await to(testDel({ examId: row.examId }))
          if (err) return this.$message({ type: 'error', message: err.msg })
          this.$message({ type: 'success', message: res.msg })
          this.getExaminationListData()
        })
        .catch(() => {})
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getExaminationListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getExaminationListData()
    },
  },
}
</script>
<style scoped lang="scss">
.testList {
  ::v-deep .el-table tr .tableLine,
  .tableList ::v-deep .el-table tr .tableRowTitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
  position: relative;
  .btns {
    position: absolute;
    right: 0;
    top: 98px;
  }
  .childBtn {
    margin: 0 10px;
  }
  .tableList ::v-deep .el-date-editor {
    width: 250px !important;
  }
}
</style>
