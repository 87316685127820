<!--
 * @Description: 邀请学员
 * @Author: xiawenlong
 * @Date: 2021-04-22 10:06:14
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-26 18:37:36
-->
<template>
  <el-dialog :close-on-click-modal="false" class="student-invite" :visible.sync="dialogVisible">
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="二维码分享" name="qr">
        <div class="qrcode">
          <img :src="inviteInfo.qrCode" alt="" />
        </div>
        <div>
          <el-button type="primary" class="childBtn" @click="keepCode">
            保存二维码
          </el-button>
          <el-button class="childBtn" @click="copyQrCode">
            复制二维码
          </el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="链接分享" name="link">
        <div
          v-clipboard:copy="link"
          v-clipboard:success="onCopySuccess"
          v-clipboard:error="onCopyError"
          class="copy"
        >
          <p :title="link">{{ link }}</p>
          <div class="btn">
            复制链接
          </div>
        </div>
        <!-- <div class="share">
          <span class="shareText">分享到</span>
          <img src="../../../../assets/examination/wx.png" alt="" @click="wxShare" />
          <img src="../../../../assets/examination/qq.png" alt="" @click="qqShare" />
        </div> -->
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import { shareLink } from '@/api/examination'
import to from 'await-to'
import { mapGetters, createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('user')
export default {
  name: 'Code',
  data() {
    return {
      activeName: 'qr',
      dialogVisible: false,
      inviteInfo: {},
      link: '',
      examTitle: '',
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapState(['collegeInfo']),
  },
  methods: {
    show(examId, examTitle) {
      this.dialogVisible = true
      this.examTitle = examTitle
      this.shareLinkData(examId)
    },
    async keepCode() {
      const img = document.querySelector('.qrcode img')
      const response = await fetch(img.src)
      const blob = await response.blob()
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = this.examTitle
      a.click()
      window.URL.revokeObjectURL(url)
    },
    async shareLinkData(examId) {
      const [res, err] = await to(shareLink({ examId: examId }))
      if (err) return this.$message.warning(err.msg)
      this.inviteInfo = res.data
      this.link = res.data.qrLink
    },
    async copyQrCode() {
      const img = document.querySelector('.qrcode img')
      const setToClipboard = blob => {
        // eslint-disable-next-line no-undef
        const data = [new ClipboardItem({ [blob.type]: blob })]
        return navigator.clipboard.write(data)
      }
      try {
        const response = await fetch(img.src)
        const blob = await response.blob()
        setToClipboard(blob)
        this.onCopySuccess()
      } catch (error) {
        this.onCopyError()
      }
    },
    onCopySuccess() {
      this.$message.success('复制成功')
    },
    onCopyError() {
      this.$message.error('复制失败')
    },
  },
}
</script>
<style lang="scss" scoped>
.student-invite {
  ::v-deep.el-dialog {
    position: relative;
    width: 560px;
    height: 350px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 560px;
      height: 6px;
      background: #ff7b33;
    }
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 24px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-tabs__item {
      font-size: 18px;
      font-weight: 400;
      color: #4d4e5d;
      margin-bottom: 5px;
      padding: 0 18px;
      &.is-active {
        color: #ff7b33;
        font-weight: 500;
      }
      &.is-top:nth-child(2) {
        padding-left: 36px;
      }
    }
    .el-tabs__active-bar {
      height: 4px;
    }
    .el-tabs__content {
      padding: 0 30px;
    }
    #pane-link {
      > p {
        font-size: 14px;
        font-weight: Normal;
        margin-bottom: 14px;
      }
      .copy {
        width: 500px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #ff7b33;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        p {
          flex: 1;
          overflow: hidden;
          @extend %one-line-text;
          padding: 0 4px;
        }
        .btn {
          cursor: pointer;
          width: 120px;
          height: 40px;
          background: #ff7b33;
          font-size: 14px;
          font-weight: Normal;
          color: #ffffff;
          text-align: center;
        }
      }
      .share {
        margin-top: 20px;
        font-size: 16px;
        color: #909399;
        display: flex;
        align-items: center;
        line-height: 30px;
        img {
          width: 24px;
          height: 24px;
          margin: 0 5px;
        }
        .shareText {
          margin-right: 20px;
        }
      }
    }
    #pane-qr {
      text-align: center;
      p {
        margin-bottom: 13px;
      }
      .qrcode {
        margin: 0 auto;
        width: 142px;
        height: 144px;
        margin-bottom: 18px;
        border: 1px solid #eee;
        img {
          @extend %width-height;
        }
      }
      .btn {
        cursor: pointer;
        margin: 0 auto;
        width: 120px;
        height: 40px;
        line-height: 40px;
        background: #ff7b33;
        font-size: 14px;
        font-weight: Normal;
        color: #ffffff;
      }
    }
    #pane-code {
      > p {
        margin-bottom: 20px;
      }
      .copy {
        width: 240px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #ff7b33;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        p {
          flex: 1;
          overflow: hidden;
          @extend %one-line-text;
          padding: 0 4px;
          text-align: center;
        }
        .btn {
          cursor: pointer;
          width: 120px;
          height: 40px;
          background: #ff7b33;
          font-size: 14px;
          font-weight: Normal;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
}
</style>
